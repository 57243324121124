@import "../bootstrap/bootstrap.less";
@import "../bootstrap-file-input.less";
@import "../mixins.less";
@import "../variables.less";
@import "../liscrolljs.less";
@import "../froala_editor.less";
@import "../froala_style.less";
@import "../image.less";
@import "../draggable.less";
@import "../code_view.less";
@import "../quick_insert.less";
@import "../../../../node_modules/font-awesome/less/font-awesome.less";
@import "../typeahead.less";
@import "../bootstrap-tagsinput.less";
@import "../daterange-picker.less";
@import "../styles.less";

//
// Front end specific code
// --------------------------------------------------

// this is to remove some of the default padding/margins bootstrap adds

.container, .container-fluid {
	padding-left: 0px;
	padding-right: 0px;	
}

.row {
	margin-left: 0px;
	margin-right: 0px;
}

.row > div {
	padding-left: 0px;
	padding-right: 0px;
}

.content {
	padding: 25px 45px;
}

.breadcrumb {
	font-size: 11px;
	margin-bottom: 0px;
	
	& li:first-child {
		padding-left: 30px;
	}
	
}

.sidebar-add {
	margin: 5%;
}

.contactlist p a {
	color: @primary-colour;
}

#searchfilters h3 {
	color: @brand-primary-colour;
}

.add-idea-wrapper {
	padding-left: 25px;
    padding-right: 25px;
}

figure, iframe {
	margin: 15px 0px;
}

.video-container iframe {
    width: 100%;
    height: 400px;
}

@media (min-width: 480px) {
    .pull-right-sm {
        float: right;
    }
}

@media (max-width: 480px) {
    .similarResults {
		
		h2 {
			padding: 0px !important;
		}
		
		.togglebtn:last-child {
	   		margin-bottom: 10px;
	   	}
	}
	
	.top-menu .navbar-nav {
		margin: 0px;
	}
    
}

@media (max-width: 700px) {
	
	body {
		background: #fff;
	}
	
	.responsive-buttons button {
		font-size: 14px;
		padding: 6px 8px;
		margin-bottom: 10px;
	}
	
	.ideasmbox {
		width: 100%;
	}
	
	.idea-meta {
		.margin-xs {
			margin-left: 3px;
		}
	}
}