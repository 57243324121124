@import 'mixins.less';
@import 'bootstrap/variables.less';
@import 'variables.less';

//
// Core skeleton styles
// --------------------------------------------------

body {
  background: url("../../../../assets/images/background.jpg") no-repeat top center @background-colour;
  color: @primary-font-colour;
  font-family: Helvetica, Arial;
}

a {
	color: @highlight-colour;
  
	&:hover {
		color: @highlight-colour-hover;
	}
  
}

#wrapper {
  background: url("../../../../assets/images/shellbg.jpg") repeat-x top left #d7e2ec;
  max-width: 1500px;
  padding: 0px 60px 57px 60px;
  .border-radius(20, 20, 20, 20);
  @media (max-width: @screen-md-max) { padding: 0px 10px 57px 10px; }
}

#innercontent {
  background: @pane-background;
}

#headerlogo {
  margin: 0px auto;
}

.ideasbank-logo img {
	padding: 35px 0px 5px 40px;
	width: 220px;
	margin-bottom: 10px;

	@media only screen and (max-width: @screen-xs-max) {
		padding-top: 27px;
	}
	
}

.org-logo img {
	padding: 20px 40px 20px 0px;
	width: 300px;
	margin-top: 10px;	
}

.light-bg {
	background: @pane-background;
}

h1 {
	color: @highlight-colour;
	font-weight: normal;
	font-size: 24px;
	line-height: 100%;
	margin: 12px 0px;
	
	@media only screen and (max-width: @screen-xs-max) {
		font-size: 18px;
		margin-top: 5px;
	}

}

h3 {
	color: @highlight-colour;
	font-size: 16px;
}

.media-responsive 
{
	& figure > img {
		width: 100% !important;
	}
	
}


//
// Main menu
// --------------------------------------------------

.top-menu {
	.linear-gradient(@menu-gradient-from, @menu-gradient-to, 40%, 100%, -180deg);
	.border-radius(6px, 0px, 0px, 6px);
	margin-bottom: 0px;
	border: none;
	padding: 3px 0px;
	
  
	& .navbar-nav>li>a {
		color: @menu-text-colour;
		border-right: 1px solid fade(@menu-gradient-from, 40%);
		border-left: 1px solid fade(@menu-gradient-to, 50%);
		padding: 13px 20px;
		
		@media (max-width: @screen-md-max) { padding: 13px 15px; }
		
		&:hover, &:focus {
			color: @menu-text-colour-hover;
		}
		
		& span:first-child {
			font-size: 80%;
			margin-right: 5px;
			color: @menu-icon-colour;
		}
		
  	}
  	
  	& .navbar-nav li {
	  	
  		&:first-child a {
			border-left: none;
		}
		
		&:last-child a {
			border-right: none;
		}
	}
 
  
	#loggedinuser {
		text-align: right;
		color: @menu-text-colour;
		font-size: 14px;
		font-style: italic;
		margin-top: 13px;
		margin-right: 30px;
		
		& .btn {
			padding: 3px 6px 2px 6px;
			margin-left: 5px;
		}
		
	}
	
	.navbar-toggle {
		margin-right: 25px;
		
		& .icon-bar {
			background-color: #fff;
		}
		
	}
  
}

.topheader {
  padding: 23px 0px 20px 35px;
  color: @primary-colour;
  font-size: 15px;
}

//
// right-hand side menu
// --------------------------------------------------

.rhscol {
	background: @sidebar-colour;
	padding-bottom: 80px;
    border-bottom-right-radius: 10px;
}

.togglebtn {
	
	display: block;
	color: @sidebar-toggle-text;
	padding: 23px 30px 20px 35px;
	font-size: 14px;
	font-weight: bold;
	.linear-gradient(@sidebar-toggle-gradient-from, @sidebar-toggle-gradient-to, 50%, 90%, -180deg);
	
	@media (max-width: @screen-md-max) {
	    padding: 15px 10px 15px 15px;
		font-size: 11px;
    }
	
	&:hover, &:active {
		text-decoration: none;
		color: @sidebar-toggle-text-hover;
		.linear-gradient(@sidebar-toggle-gradient-from-hover, @sidebar-toggle-gradient-to-hover, 50%, 90%, -180deg);
	}
	
	& span {
		font-size: 20px;
		
		@media (max-width: @screen-md-max) {
			font-size: 11px;
	    }
		
	}

}

.displaylist {
	.linear-gradient(@sidebar-toggle-content-bg-from, @sidebar-toggle-content-bg-to, 50%, 90%, -180deg);
	padding: 23px 0px 20px 35px;
	display: none;
	width: 100% !important;
	
	@media (max-width: @screen-md-max) {
		padding: 10px 0px 10px 10px;
	}
	
}

.displaylistitem {
	color: @sidebar-toggle-content-font;
	display: block;
	margin: 5px 35px 5px 10px;
	font-size: 13px;
	
	@media (max-width: @screen-md-max) {
		font-size: 11px;
		margin-right: 10px;
	}
	
	&:hover {
		text-decoration: none;
		color: @sidebar-toggle-content-font-hover;
	}
	
	&span {
		font-size: 20px;
		
		@media (max-width: @screen-md-max) {
			font-size: 11px;
		}
		
	}
	
}

.contactlist {
	padding-top: 25px;
	
	& div {
		float: left;
		width: 55px;
		margin-left: 35px;
		
		@media (max-width: @screen-md-max) {
		    display: none;
		}
		
	}
	
	& p {
		margin-top: 3px;
		font-size: 12px;
		color: #004fa2;
		float: left;
		width: 130px;
		
		@media (max-width: @screen-md-max) {
		    margin-left: 15px;
		}
		
		& a {
			color: #fff;
		}
		
	}

}

.thidea {
  background: none;
}

.lightheader {
  background: url("../../../../assets/images/lightheader.png") no-repeat top left;
  padding: 2px 0px 12px 35px;
  color: #000;
  font-size: 15px;
  margin-top: 50px;
}

.arrowbg {
	height: 36px;
	background: url("../../../../assets/images/arrowbg.png") no-repeat top left;
	padding: 15px 0px 0px 50px;
	display: block;
	color: #fff;
	font-weight: bold;
	
	&:hover {
		color: #6ee3e8;
		text-decoration: none;
	}
}

#myideasbox {
	background: @myideas-bg;
	.border-radius(15px, 15px, 15px, 15px);
	padding: 10px 16px 25px 16px;
	margin: 30px 30px;
	
	& .ideasmbox {
		float: left;
		margin-left: 15px;
	}
	
	& h3 {
		font-weight: normal;
		color: #193363;
		font-size: 16px;
		margin: 10px 0px 0px 5px;
	}
}

.ideasmbox {
	background: @myideas-item-bg;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	width: 245px;
	margin: 10px 32px 0px 5px;
	padding: 12px 16px;
	color: @myideas-item-text;
	margin-left: 5px !important;
	font-size: 13px;
	
	& a {
		color: @myideas-item-link;
		text-decoration: none;
		
		&:hover {
			color: @myideas-item-link-hover;
		}
		
		&.newcomments {
			color: @myideas-item-comments-link;
			font-size: 11px;
			margin-right: 8px;
			
			&:hover {
				color: @myideas-item-comments-link-hover;
			}
			
		}
		
	}

}

// Announcement box
#announcement {
	padding: 15px;
}
#announcement h4 {
	font-size: 13px;
	font-weight: 600;
	color: grey;
}
#announcement p {
	font-size: 12px;
	color: grey;
}
p#announcement-quote {
	font-size: 15px;
}
p#announcement-source {
	text-transform: uppercase;
	letter-spacing: 1.25px;
}
p#announcement-context {
	font-style: italic;
}


//
// Index primary callout box
// --------------------------------------------------

.image1 {
  background: url("../../../../assets/images/frontimgs/image1.jpg") no-repeat top left;
  background-size: cover;
  height: 335px;
  
  @media only screen and (max-width: @screen-md-max) {
		height: 260px;
	}
  
}

#addbox {
  margin-left: 50%;
  height: 330px;
  padding: 10px 36px 0px 60px;
  color: #fff;
  
  @media only screen and (max-width: @screen-xs-max) {
		text-align: center;
		padding: 10px 30px 0px 30px;
		margin-left: 0%;
	}
  
  & h2 {
	  color: @intro-text-heading;
	  font-size: 28px;
	  margin-bottom: 20px;
	  margin-top: 50px;
	  margin-left: 20px;
	  font-weight: 300;
	  
	  @media only screen and (max-width: @screen-xs-max) {
		/*background: fade(@intro-text-heading, 88%);*/
		margin-left: 0px;
		color: #fff;
		padding: 10px 20px;
	  }
	  
	  @media only screen and (max-width: @screen-md-max) {
		font-size: 20px;
		text-shadow: 1px 1px 1px rgb(95, 90, 90);
		}
	  
  }
  
  & p {
	  font-size: 16px;
	  color: @intro-text-desc;
	  margin-left: 55px;
	  font-weight: 300;
	  line-height: 130%;
  }
  
  & #addbtn {
	  margin-top: 20px;
	  margin-left: 88px;
	  border-radius: 10px 10px 10px 10px;
	  background-image: url("/assets/images/addbtn.png");
	  background-repeat: no-repeat;
	  width: 224px;
	  height: 47px;
	  padding: 0;
	  
	  @media only screen and (max-width: @screen-xs-max) {
		margin-left: 0px;
	  }
	  
	  @media only screen and (max-width: @screen-md-max) {
		font-size: 25px;
		  /*margin-left: 28px*/
		}
	  
	  &:hover {
		  -webkit-animation-name: whitePulse;
		  -webkit-animation-duration: 2s;
		  -webkit-animation-iteration-count: 1;
		  -webkit-box-shadow: 0 0 18px @intro-btn-glow;
	  }
	  
  }
  
}

#completedideasbox {
	
  margin: -48px 30px 0px 30px;
  .drop-shadow (0px, 4px, 15px, 0px, @completed-idea-shadow);
  height: 153px;
  
  & h2 {
	  color: @completed-heading;
	  font-size: 16px;
	  background: @completed-heading-bg;
	  padding: 16px 20px 14px 20px;
	  font-weight: 300;
	  margin: 0px;
  }
  
  & #completedwrapper {
	  
	  .linear-gradient(@completed-idea-gradient-from, @completed-idea-gradient-to, 0, 100%, -51deg);
	  border-top: 0px solid #ccd8e3;
	  border-bottom: 1px solid #ccd8e3;
	  
	  & ul {
		width: 100%;
		margin: 0px 0px 0px 0px;
		padding: 0px 0px 0px 0px;
		
		& li {
		  .linear-gradient(@completed-idea-gradient-from, @completed-idea-gradient-to, 0, 100%, -51deg);
		  height: 66px;
		  width: 242px !important;
		  padding: 20px;
		  white-space: normal;
		  float: left;
		  margin-top: 1px;
		  min-height: 105px;
		  
		  & span {
			  color: #c1c1c1;
			  font-size: 11px;
		  }
		  
		  & a {
			  color: @completed-idea-title;
			  text-decoration: none;
			  font-weight: 200;
			  height: 100%;
			  overflow: hidden;
			  display: block;
			  margin-bottom: 3px;
			  
			  &:hover {
				  color: @completed-idea-title-hover;
			  }
			  
		  }
		  
		  .newoverlay {
			  position: absolute;
			  margin: 47px 0px 0px 149px;
			  background: url("../../../../assets/images/newoverlay.png") no-repeat bottom left;
			  width: 39px;
			  height: 39px;
		   }
		  
		}
		
	  }
	  
	  #overlayrbtn {
		  display: block;
		  width: 50px;
		  height: 105px;
		  position: absolute;
		  right: 30px;
		  background: url("../../../../assets/images/overlayrbtn.png") no-repeat top right;
		  z-index: 200;
		  
		  & span {
		  	display: none;
		  }
		  
		}
		
		
	  
	}
	
	.overlay-pager {
	  margin: -28px 20px 0px 0px;
	  float: right;
	  color: #c0cfdf;
	  font-size: 20px;
	  
	  & span {
		  margin-left: 3px;
		  cursor: pointer;
	  }
	  
	}
	
	.cycle-pager-active {
		color: #193363;
	}
  
}

#infoboxes {
	
	padding: 0px 30px 0px 0px;
	
	& .row {
		margin: 0px;
	}

}

.frontsearchbox {
  background: #ecf0f3;
  padding: 17px 17px 17px 17px;
  margin-left: 30px;
  margin-bottom: 25px;
  
  & h5 {
  	color: #193363;
  	font-weight: normal;
  	font-size: 14px;
  	margin-bottom: 5px;
  }
  
  & p {
	color: #737373;
    margin-bottom: 15px;
    font-size: 12px;
  }
  
  & .search-query {
	  padding-left: 0px;
	  padding-right: 3px;
	  padding-bottom: 20px;
  }
  
  & .clearfix {
	  padding-bottom: 17px;
  }
  
}

.redarrow {
  color: #738087;
  background: url("../../../../assets/images/redarrow.png") no-repeat left;
  padding-left: 35px;
  margin-left: 28px;
}

// 
// Home Page Middle Section
//
#home-midsection-title {
	font-size: 16px;
	margin-left: 30px;
	margin-top: 30px;
}

//
// Add an idea page
// --------------------------------------------------

.explainpane {
	padding: 20px 0px 0px 160px;
	margin: 60px 0px 50px 0px; 
	
	@media only screen and (max-width: @screen-sm-max) {
		padding: 0px 0px 0px 160px;
		margin: 0px 0px 50px 0px;
	} 
}

.explainpane-img {
	margin-top: 90px;
	padding-right: 20px;
	
	@media only screen and (max-width: @screen-sm-max) {
		margin-top: 20px;
	} 
	
}

#addideaform label {
	color: #172f5b;
}

.form-horizontal .form-actions {
	padding-left: 120px;
	margin-left: 40px;
	margin-right: 40px;
}

#multifileupload input {
	clear: both;
}

.required {
	color: #bb0606 !important;
}


//
// View an individual idea page
// --------------------------------------------------


.countbox {
	
	text-align: center;
	padding: 30px 0px 20px 30px;
	
	@media only screen and (max-width: @screen-xs-max) {
		padding: 1px 0px 10px 0px;
	}
	
	& .countbox-count {
		
		border: 1px solid #e3e3e3;
		padding: 2px 0px;
		color: @gray-light;
		
		@media only screen and (min-width: @screen-xs-max) {
			border-bottom: none;
			padding: 10px 0px;
		}
		
		& b {
			
			line-height: 100%;
			
			@media only screen and (min-width: @screen-xs-max) {
				display: block;
				font-size: 50px;
			}
			
		}
		
	}
	
	& .countbox-status {
		
		color: #fff;
		padding: 5px 0px;
		font-size: 11px;
		margin-bottom: 10px;
		
		@media only screen and (min-width: @screen-xs-max) {
			-webkit-border-bottom-right-radius: 10px;
			-webkit-border-bottom-left-radius: 10px;
			-moz-border-radius-bottomright: 10px;
			-moz-border-radius-bottomleft: 10px;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
		
	}
	  
}

.ideacol2 {
	
  margin: 25px 45px 0px 30px;
  
  & h2 {
  	font-size: 12px;
	color: #7fa6cd;
	font-weight: normal;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
  }
  
  & h3 {
	  font-size: 12px;
	  color: #b2b2b2;
	  font-weight: normal;
	  letter-spacing: 2px;
	  text-transform: uppercase;
	  margin: 20px 0px 5px 0px;
  }
  
  & p {
	  font-size: 13px;
	  line-height: 150%;
	  color: #4c4c4c;
	  margin-left: 10px;
	  margin-top: 10px;
  }
  
  & #statushistory {
	  clear: both;
	  margin-top: 10px;
	  display: none;
  }
  
}

.byline {
  margin: 8px 0px 5px 0px;
  
  & .label {
	  padding: .4em .6em .3em;
	  font-size: 13px;
	  font-weight: 300;
  }
  
  & .joint-idea {
	 color: @gray-light;
	 font-size: 11px;
	 margin-top: 3px;
  }
  
}

.comment-date {
  color: lighten(@gray-light, 20%);
  font-size: 11px;
  margin-top: 6px;
}

.commentbox {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  padding: 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.bubble {
  background: url("../../../../assets/images/bubble.gif") no-repeat top left;
  width: 25px;
  height: 18px;
  position: absolute;
  margin: 18px 0px 0px -24px;
}

.tag-holder  {
	
	margin-top: 15px;
	
	.label a {
		color: white;
	}
	
}

//
// Business unit page
// --------------------------------------------------

.unittextbox {
	color: #727272;
	font-size: 14px;
	line-height: 140%;
	margin-top: 20px;
}

//
// Browse all ideas
// --------------------------------------------------

#browseframe, .content-padding {
  padding: 20px 40px;
}

#showfilters {
  background: url("../../../../assets/images/garrow.gif") no-repeat right #e8e8e8;
  padding: 15px 15px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  font-style: italic;
  margin-top: 25px;
  
  & span {
	  color: #0053a0;
  }
  
}

.searchresult-wrapper {
	padding: 0px 20px 20px 20px;
}

.searchresult {
  padding: 0px 0px 15px 0px;
  border-bottom: 1px solid #e1e1e1;
  min-height: 75px;
  height: auto !important;
  height: 75px;
  margin: 0px;
  
  & h2 {
	  font-weight: normal;
	  color: #004e9c;
	  font-size: 17px;
	  line-height: 100%;
	  margin-bottom: 8px;
  }
  
  & .idea-meta {
		margin-top: 15px;
		text-align: right;
		color: #0f0f0f;
		font-size: 11px;
		font-style: italic;
	  
		@media only screen and (max-width: @screen-xs-max) {
			text-align: left;
		}
	  
	  & .label {
		  font-size: 95%;
		  margin-top: 3px;
		  display: inline-block;
	  }
	  
  }
  
  & p {
	font-size: 12px;
	color: #727272;
	width: 100%;  
  }
  
  
  
}

.smalltext {
  color: #b2b2b2 !important;
  margin-bottom: 3px !important;
  font-size: 11px !important;
}

.flm {
  padding: 20px 0px 25px 0px;
  min-height: 20px;
  height: auto !important;
  height: 20px;
}

.browsedate {
  color: #b2b2b2;
  margin-bottom: 3px;
}

.readmoretext {
  display: none;
}

.external {
  position: absolute;
  margin: -26px 0px 0px 460px;
}

#searchfilters {
  padding: 0px 15px;
  
  & h2 {
	  padding: 53px 0px 4px 0px;
	  color: @secondary-colour-alt1;
	  font-size: 18px;
	  border-bottom: 1px solid #cedce2;
  }
  
  & h3 {
	  color: @primary-colour;
	  font-size: 12px;
	  font-style: italic;
	  margin-top: 10px;
	  margin-bottom: 0px;
  }
  
}

.break {
  border-bottom: 1px solid #cedce2;
}

#similarResults {
  padding-top: 175px;
}

#newideaarrow {
  position: absolute;
  margin: 0px 0px 0px 465px;
  display: none;
}

.readmoretext {
	display: none;
}

//
// Bootstrap alts - check these as they might not be needed anymore
// --------------------------------------------------

.form-section-heading {
  padding: 17px 20px 18px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: #eeeeee;
  border-top: 1px solid #ddd;
  *zoom: 1;
}
.form-horizontal .form-section-heading {
  padding-left: 120px;
  margin-left: 40px;
  margin-right: 40px;
}
td.action-column {
  text-align: center;
}
table#payments td.action-column {
  width: 85px;
}
td.action-columnsb {
  width: 50px;
  text-align: center;
}
.tab-content .active {
  display: block;
}
.smallgrey {
  font-size: 11px;
  color: #676767;
}
.tableheaderbox {
  background: #e1e1e1;
}
.tableinset {
  padding-left: 15px;
}
.exportOptions {
  padding: 10px;
}


/*
 * Style tweaks
 * --------------------------------------------------
 */
html,
body {
  overflow-x: hidden;
}


/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: 767px) {
	
	#wrapper {
	  padding: 0px !important;
	}
	
  .row-offcanvas {
    position: relative;
    -webkit-transition: all .25s ease-out;
         -o-transition: all .25s ease-out;
            transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
    border-right: 6px solid @secondary-colour;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right
  .sidebar-offcanvas {
    right: -50%; /* 6 columns */
  }

  .row-offcanvas-left
  .sidebar-offcanvas {
    left: -50%; /* 6 columns */
  }

  .row-offcanvas-right.active {
    right: 50%; /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 50%; /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 50%; /* 6 columns */
  }
  
  #offcanvas-btn {
	  border: none;
	  background: @secondary-colour;
	  margin-top: 25px;
	  .border-radius(0px, 0px, 15px, 15px);
	  padding: 20px 4px 20px 10px;
	  
	  & span {
		  color: @primary-colour;
	  }
	  
	  &:focus {
		  outline: none;
	  }
	  
  }
  
  #browseframe {
	  padding: 10px 25px;
	}
  
}

//
// Animation presets
// --------------------------------------------------

@-webkit-keyframes whitePulse {
  from { -webkit-box-shadow: 0 0 0 fade(@intro-btn-glow, 50%); }
  to { -webkit-box-shadow: 0 0 18px @intro-btn-glow; }
}

//
// Responsive Adjustments
// --------------------------------------------------

@media only screen and (max-width: 1149px) {

	#addbox h2 {
		margin-top: 0px !important;
	}

	#addbox p {
		margin-left: 0px !important;
	}

	#addbtn {
		margin: auto !important;
	}

}

//
// Customisations
// --------------------------------------------------

.industry-cb {
  .checkbox {
    float: left;
  }
  >.form-group {
    float: left;
  }
  .industry-cb-label {
    padding-top: 8px;
    display: inline-block;
  }
}