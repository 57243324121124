//
// liScroll - front page ticker styles
// --------------------------------------------------

#tickerwrapper,
#ideatickerwrapper {
  border-top: 1px solid #ccd8e3;
  border-bottom: 1px solid #ccd8e3;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
}

#latestnews {
  font-size: 11px;
  position: absolute;
  margin: 13px 0px 0px 33px;
  color: #727272;
}

.tickercontainer {
  /* the outer div with the black border */
  width: 100%;
  height: 40px;
  overflow: hidden;
  padding-top: 12px;
}
#ideatickerwrapper .tickercontainer  {
  /* the outer div with the black border */
  width: 100%;
  height: 200px;
  overflow: hidden;
  padding-top: 0px;
}
.tickercontainer .mask {
  /* that serves as a mask. so you get a sort of padding both left and right */
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-left: 100px;
}
ul.newsticker {
  /* that's your list */
  position: relative;
  left: 820px;
  list-style-type: none;
}
ul.newsticker li {
  float: left;
  /* important: display inline gives incorrect results when you check for elem's width */
  margin: 0;
  padding: 0;
  font-size: 12px;
}
ul.newsticker a {
  /* white-space: nowrap; */
  color: #1369c4;
  margin: 0 18px 0 0;
  text-decoration: none;
  font-weight: bold;
}
ul.newsticker a:hover {
  color: #004fa2;
}
ul.newsticker span {
  margin: 0px 5px 0px 0px;
  color: #727272;
  font-weight: normal;
  white-space: nowrap;
}