.border-radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -webkit-border-top-left-radius: @topleft;
  -moz-border-radius-topright: @topright;
  -moz-border-radius-bottomright: @bottomright;
  -moz-border-radius-bottomleft: @bottomleft;
  -moz-border-radius-topleft: @topleft;
  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
  border-top-left-radius: @topleft;
}

.linear-gradient(@color1:#ccc, @color2:#fff, @stop1:0, @stop2:100%, @deg:0deg) {
  @old-deg: @deg + 90deg;
  background-color: @color2;
  background: -webkit-linear-gradient(@old-deg, @color1 @stop1, @color2 @stop2);
  background:    -moz-linear-gradient(@old-deg, @color1 @stop1, @color2 @stop2);
  background:     -ms-linear-gradient(@old-deg, @color1 @stop1, @color2 @stop2);
  background:      -o-linear-gradient(@old-deg, @color1 @stop1, @color2 @stop2);
  background:         linear-gradient(@deg, @color1 @stop1, @color2 @stop2);
  filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr='#cccccc', endColorstr='#000000')";
}

.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @colour) {
	-webkit-box-shadow:	@x @y @blur @spread @colour;
	-moz-box-shadow:	@x @y @blur @spread @colour;
	box-shadow:		@x @y @blur @spread @colour;
}